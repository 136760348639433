.About {
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
}

.About .title-wrapper {
  padding: 1rem;
  position: relative;
  z-index: 10;
  text-align: left;
}

.About .title {
  margin: 0px;
  font-size: 3rem;
  color: #BBB;
  letter-spacing: 1px;
  font-family: 'Century Gothic', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.About .content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 95%;
  min-height: calc(100% - 90px + 1rem);
  margin-top: -1rem;
  position: relative;
  top: 0px;
  font-family: 'Century Gothic', sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
}

.About .credits {
  width: 34%;
  text-align: center;
}

.About .description {
  width: 66%;
  padding: 1rem 4rem;
  
}

.About .description > p {
  font-size: .9rem;
  color: #DDD;
  text-align: justify;
}

.About .description > p.Alencar > b{
  font-size: .9rem;
  color: #FFF;
}

.About .description > p.Alencar {
  color:#BBB;
}

.About .angle {
  display: none;
}

.About .logo img {
  max-width: 192px;
  max-height: 192px;
}



/*media queries*/
@media only screen and (max-width: 1080px) {
  .About .content {
    flex-direction: row;
    overflow-x: auto;
    justify-content: initial;
    scroll-snap-type: x mandatory;
  }

  .About .content .description {
    margin-left: 5%;
    margin-right: 5%;
    min-width: 90%;
    padding: 0px;
    scroll-snap-align: center;
  }

  .About .content .credits {
    min-width: 100%;
    padding: 0px;
    scroll-snap-align: center;
  }

  .About .angle {
    position: absolute;
    right: 0;
    padding: 1rem;
    top: 0;
    font-size: 3rem;
    color: #BBB;
    display: block;
    margin-top: 0.35rem;
  }

  .About .angle:hover {
    cursor: pointer;
  }

  /*Hide scrollbar for Chrome, Safari and Opera*/
  .About .content::-webkit-scrollbar {
    display: none;
  }

  /*Hide scrollbar for IE, Edge and Firefox*/
  .About .content {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media only screen and (max-width: 820px) {
  .About .content .description > p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 600px) {
  .About .content .description > p {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1600px) {
  .About .content .description > p {
    font-size: 1rem;
  }
}