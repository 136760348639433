.Album {
  width: 100%;
  height: 100%;
  position: absolute;
}

.Album .title-wrapper {
  padding: 1rem;
  background-image: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.3), rgba(0,0,0,0));
  position: relative;
  z-index: 10;
  text-align: left;
}

.Album .title-wrapper.noblack {
  background-image: none;
}

.Album .title-wrapper:hover{
  opacity: 1 !important;
}

.Album .title {
  margin: 0px;
  font-size: 3rem;
  color: #BBB;
  letter-spacing: 1px;
  font-family: 'Century Gothic', sans-serif;
  font-weight: bold;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.Album>*:not(.photos-wrapper) {
  transition: opacity .5s;
  opacity: 1;
}

.Album.quiet>*:not(.photos-wrapper) {
  opacity: 0;
}

/* ============== PHOTOS ==================  */


.Album .angle {
  position: absolute;
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  top: 0px;
}

.Album .angle:hover{
  opacity: 1;
}

.Album .angle.right {
  right: 0px;
}

.Album .angle > .fa {
  padding: 1rem;
  font-size: 5rem;
  color: #ccc;
}

.Album .angle > .fa:hover:not(.disabled) {
  color: #eee;
  cursor: pointer;
  opacity: 1;
}

.Album .angle > .fa.disabled {
  opacity: 0.2;
}

.Album .photos-wrapper {
  width: 100%;
  height: 100%;
  top: 0px;
  position: absolute;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

/*Hide scrollbar for Chrome, Safari and Opera*/
.Album .photos-wrapper::-webkit-scrollbar {
  display: none;
}

/*Hide scrollbar for IE, Edge and Firefox*/
.Album .photos-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 820px) {
  .Album .angle {
    width: 10%;
  }
}

@media only screen and (max-width: 480px) {
  .Album .angle {
    width: 15%;
  }
}



/* =============== COUNTER =================  */

.Album .counter {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 1rem;
  z-index: 10;
}

.Album .counter>h4{
  margin: 0px;
  font-size: 1.2rem;
  color: #bbb;
  letter-spacing: 8px;
}