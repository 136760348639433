.Photo {
  min-width: 100%;
  scroll-snap-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
}

.Photo.multiple {
  display: flex;
}

.Photo.multiple>div{
  width: 100%; height: 100%; 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}