.App {
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  background-color: black;
}

/*media queries for mobile and about scroll to end*/
@media only screen and (max-width: 1080px) {
  .App>#a0 .About{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: auto;
  }

  .App>#a0 span {
    display: none;
  }

  .App>#a0 .content {
    display: block;
    height: auto;
  }

  .App>#a0 .description {
    margin-top: 4rem;
  }

  .App>#a0 .credits {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  /*Hide scrollbar for Chrome, Safari and Opera*/
  .App>#a0 .About::-webkit-scrollbar {
    display: none;
  }

  /*Hide scrollbar for IE, Edge and Firefox*/
  .App>#a0 .About {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

html {
  background-color: black;
}

.hide {
  display: none !important;
}
