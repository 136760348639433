.Credit {
  padding: 8px;
}

.Credit .position{
  margin: 0px;
  font-size: .9rem;
  color: #DDD;
}

.Credit .person{
  margin: 0px;
  font-weight: normal;
  font-size: .9rem;
  color: #AAA;
}

.Credit .email{
  margin: 0px;
  font-size: .9rem;
  display: block;
  color: white;
}

@media only screen and (min-width: 1600px) {
  .Credit .position {
    font-size: 1rem;
  }

  .Credit .person {
    font-size: 1rem;
  }

  .Credit .email {
    font-size: 1rem;
  }
}