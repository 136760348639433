.ventanamon-logo {
  opacity: 0;
  animation-name: ventanamon;
  animation-duration: 2s;
  animation-delay: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  max-width: 100%;
  max-height: 100%;
}

.hint-down { 
  color: #ccc;
  font-size: 5rem;
  position: absolute;
  padding: 1rem;
  bottom: 0px;
  animation-name: hint;
  animation-duration: 4s;
  animation-delay: 4s;
  animation-iteration-count: 5;
  opacity: 0;
}

@keyframes ventanamon {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes hint {
  0%   { opacity: 0; }
  20%  { opacity: 0; cursor: pointer; }
  35%  { opacity: 1; cursor: pointer; }
  50%  { opacity: 0; cursor: pointer; }
  65%  { opacity: 1; cursor: pointer; }
  80%  { opacity: 0; }
  100% { opacity: 0; }
}